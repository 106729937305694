/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { fetchCart, validateCart } from "../api/cart";
import { primaryColor, secondaryColor } from "../constants/colors";

export const PLATFORMS = {
  WEB: "web",
  MOBILE: "mobile",
};

const formatPrice = (price) => price.toFixed(2).replace(".", ",");

const CheckoutPage = () => {
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const isWebCheckout = searchParams.get("callback");
  const platform =
    searchParams.get("platform") ?? isWebCheckout
      ? PLATFORMS.WEB
      : PLATFORMS.MOBILE;
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [prices, setPrices] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [legalItems, setLegalItems] = useState({
    generalTerms: false,
    withdrawal: false,
  });

  const validate = async () => {
    setIsLoading(true);

    // Check for Overlapping bookings etc
    const cartIsValid = await validateCart(token);

    if (!cartIsValid) {
      getCart();

      toast({
        status: "error",
        title: "Buchung nicht möglich",
        description:
          "Eine Buchung in Deinem Warenkorb ist nicht mehr verfügbar und wurde entfernt.",
        duration: 5000,
        isClosable: false,
      });
    } else {
      return navigate(
        {
          pathname: "/payment",
          search: createSearchParams({
            token,
            platform,
          }).toString(),
        },
        {
          state: {
            token,
            platform,
            cartItems: items,
            finalPrice: prices?.formattedTotalPriceWithVat,
          },
        }
      );
    }
  };

  useEffect(() => {
    getCart();
  }, [token, setItems, setIsLoading, toast]);

  const getCart = () => {
    setIsLoading(true);
    fetchCart(token)
      .then((cartItems) => {
        setItems(
          cartItems.map((item) => {
            return {
              id: item.id,
              name: item.name,
              date: dayjs(item.start).format("DD.MM.YYYY"),
              startTime: dayjs(item.start).hour(),
              category: item.category,
              orientation: item.orientation,
              duration: item.duration,
              durationLabel: item.durationLabel,
              price: item.price,
              formattedPrice: item.price.toFixed(2).replace(".", ","),
            };
          })
        );

        const totalPrice = cartItems.reduce((sum, item) => sum + item.price, 0);
        const vat = totalPrice * 0.19;
        const totalPriceWithVat = totalPrice * 1.19;

        setPrices({
          totalPrice,
          formattedTotalPrice: formatPrice(totalPrice),
          vat,
          formattedVat: formatPrice(vat),
          totalPriceWithVat,
          formattedTotalPriceWithVat: formatPrice(totalPriceWithVat),
        });

        setIsLoading(false);
      })
      .catch(() => {
        toast({
          status: "error",
          title: "Fehler",
          description:
            "Ein Fehler ist aufgetreten. Versuche es später noch einmal.",
          duration: 2000,
          isClosable: true,
        });
        setIsLoading(false);
      });
  };

  const toggleGeneralTermsHandler = () =>
    setLegalItems((prev) => ({ ...prev, generalTerms: !prev.generalTerms }));

  const toggleWithdrawalHandler = () => {
    setLegalItems((prev) => ({ ...prev, withdrawal: !prev.withdrawal }));
  };

  if (isLoading) {
    return (
      <Flex px={12} py={12} justifyContent="center">
        <Spinner />
      </Flex>
    );
  }

  if (!items.length) {
    return (
      <Flex px={12} py={12} justifyContent="center">
        <Text textAlign="center">
          Dein Warenkorb ist leer. Füge Produkte zu deinem Warenkorb hinzu, um
          sie hier zu bezahlen.
        </Text>
      </Flex>
    );
  }

  return (
    <Box px={[4, 4, 12]} maxWidth="1000px" mx="auto">
      <Flex pt={4} pb={6} justifyContent="center">
        <Heading as="h1" fontSize="2xl">
          Warenkorb
        </Heading>
      </Flex>
      <Box overflowX="auto" whiteSpace="nowrap">
        <Table variant="simple" mb={4}>
          <Thead>
            <Tr>
              <Th px={0} isTruncated>
                Produkt
              </Th>
              <Th px={0} isNumeric>
                Preis
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.map(
              ({
                id,
                name,
                date,
                startTime,
                category,
                orientation,
                duration,
                durationLabel,
                formattedPrice,
              }) => (
                <Tr key={id}>
                  <Td px={0} isTruncated>
                    <Flex flexDirection="column">
                      <Text as="b">{name}</Text>
                      <Text fontSize="sm">Am {date}</Text>
                      <Text fontSize="sm">Beginn: {startTime}:00 Uhr</Text>
                      <Text fontSize="xs">
                        Kategorie: {category} {orientation}
                      </Text>
                      <Text fontSize="xs">
                        {duration} {durationLabel}
                      </Text>
                    </Flex>
                  </Td>
                  <Td px={0} isNumeric verticalAlign="top">
                    {formattedPrice} €
                  </Td>
                </Tr>
              )
            )}
          </Tbody>
        </Table>
      </Box>
      <Flex minWidth="full">
        <Text marginLeft="auto" fontSize="md" pt={5}>
          Gesamt (Netto): {prices?.formattedTotalPrice} €
        </Text>
      </Flex>
      <Flex minWidth="full">
        <Text marginLeft="auto" fontSize="md" pt={5}>
          Zzgl. Mehrwertsteuer 19%: {prices?.formattedVat} €
        </Text>
      </Flex>
      <Flex minWidth="full">
        <Text marginLeft="auto" fontSize="lg" fontWeight="bold" pt={5} pb={5}>
          Gesamt (Brutto): {prices?.formattedTotalPriceWithVat} €
        </Text>
      </Flex>
      <Flex minWidth="full" flexDirection="column" alignItems="flex-end" pb={5}>
        <Flex
          flexDirection="column"
          gridGap="10px"
          width={{ base: "100%", lg: "30%" }}
        >
          <Flex gridGap="10px">
            <Checkbox
              colorScheme="purple"
              onChange={toggleGeneralTermsHandler}
            />
            <Text fontSize="sm">
              Ich habe die{" "}
              <Link
                color={secondaryColor}
                href="https://leeve.io/agb"
                target="_blank"
              >
                AGB
              </Link>{" "}
              gelesen und zur Kenntnis genommen.*
            </Text>
          </Flex>
          <Flex gridGap="10px">
            <Checkbox colorScheme="purple" onChange={toggleWithdrawalHandler} />
            <Text fontSize="sm">
              Ich habe die{" "}
              <Link
                color={secondaryColor}
                href="https://leeve.io/datenschutzerklarung"
                target="_blank"
              >
                Datenschutzerklärung
              </Link>{" "}
              gelesen und zur Kenntnis genommen.*
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex minWidth="full" pt={2} pb={50}>
        <Button
          isDisabled={!legalItems.generalTerms || !legalItems.withdrawal}
          marginLeft="auto"
          backgroundColor={primaryColor}
          color={secondaryColor}
          border={`${primaryColor} 1px solid`}
          _hover={{
            backgroundColor: "transparent",
            color: { secondaryColor },
          }}
          onClick={validate}
        >
          Jetzt Buchen
        </Button>
      </Flex>
    </Box>
  );
};

export default CheckoutPage;
