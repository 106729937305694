import { useEffect, useState } from "react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import { createSearchParams, useLocation } from "react-router-dom";
import { captureOrder } from "../api/payment";
import {
  WEBVIEW_MESSAGES,
  postMessageToWebview,
  CHANNEL_NAME,
} from "../lib/webview";
import { PLATFORMS } from "./checkout";

const CompletedPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  const toast = useToast();
  const location = useLocation();

  useEffect(() => {
    if (!location.search) return;
    setIsLoading(true);

    const params = new URLSearchParams(location.search);
    const paymentType = params.get("payment_type");
    const token = params.get("token");
    const payment_intent_id = params.get("payment_intent_id");
    const platform = params.get("platform");

    captureOrder(token, { type: paymentType, id: payment_intent_id }).then(
      (orderData) => {
        if (platform === PLATFORMS.WEB) {
          return window.open(
            `${process.env.REACT_APP_WEB_REDIRECT_URL}?${createSearchParams({
              success: !!orderData,
              orderId: orderData?.id,
              token,
            }).toString()}`,
            "_self"
          );
        }

        if (!!orderData) {
          setIsSuccess(true);
          toast({
            status: "success",
            title: "Bezahlung erfolgreich",
            duration: 2000,
            isClosable: true,
          });
        } else {
          setIsSuccess(false);
          toast({
            status: "error",
            title: "Bezahlung Fehlgeschlagen",
            description: "Bitte versuche es später noch einmal.",
            duration: 2000,
            isClosable: true,
          });
        }
        setIsLoading(false);
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoading, location]);

  const closeWindow = () => {
    if (window?.[CHANNEL_NAME]) {
      if (isSuccess) {
        postMessageToWebview(WEBVIEW_MESSAGES.CLOSE_CHECKOUT_SUCCESS);
      } else {
        postMessageToWebview(WEBVIEW_MESSAGES.CLOSE_CHECKOUT_FAILURE);
      }
    }
  };

  if (isLoading) {
    return (
      <Flex px={12} py={12} flexDirection="column" alignItems="center">
        <Spinner />
        <Text pt={6}>Deine Zahlung wird verarbeitet...</Text>
      </Flex>
    );
  }

  return (
    <Box py={12} px={12} width="full">
      <Flex
        width="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        py={4}
      >
        <Text textAlign="center">
          {isSuccess ? (
            <>
              <CheckIcon color="green.400" /> Bezahlung erfolgreich
            </>
          ) : (
            <>
              <CloseIcon color="red.400" /> Bezahlung fehlgeschlagen
              <br />
              Bitte versuche es später noch einmal.
            </>
          )}
        </Text>
        <Button onClick={closeWindow} mt={4}>
          Fenster Schließen
        </Button>
      </Flex>
    </Box>
  );
};

export default CompletedPage;
