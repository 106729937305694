export const CHANNEL_NAME = "flutter_webview";

export function postMessageToWebview(message) {
  if (window?.[CHANNEL_NAME]) {
    window[CHANNEL_NAME].postMessage(message);
  }
}

export const WEBVIEW_MESSAGES = {
  CLOSE_CHECKOUT_SUCCESS: "CLOSE_CHECKOUT_SUCCESS",
  CLOSE_CHECKOUT_FAILURE: "CLOSE_CHECKOUT_FAILURE",
  UNAUTHORIZED_RELOAD_WEBVIEW: "UNAUTHORIZED_RELOAD_WEBVIEW",
};
