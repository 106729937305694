import { useEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { Button, Spinner, Text, useToast } from "@chakra-ui/react";
import { primaryColor, secondaryColor } from "../constants/colors";
import { PAYMENT_TYPES } from "../api/payment";
import { fetchUser } from "../api/cart";

const PurchaseOnAccount = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    if (!location.state?.token) {
      return;
    }

    setLoading(true);

    fetchUser(location.state.token)
      .then((userData) => {
        setUser(userData);
        setLoading(false);
      })
      .catch((error) => {
        toast({
          status: "error",
          title: "Fehler",
          description: error,
        });
      });
  }, [location.state, setLoading, setUser, toast]);

  const handlePurchaseOnAccountClick = () => {
    setLoading(true);

    const path = `/completed?${createSearchParams({
      token: location.state.token,
      payment_type: PAYMENT_TYPES.PURCHASE_ON_ACCOUNT,
      payment_intent_id: "null",
      platform: location.state.platform,
    })}`;

    navigate(path);
  };

  if (loading) {
    return <Spinner />;
  }

  if (!loading && !user) {
    return (
      <Text>Etwas ist schief gelaufen. Bitte versuche es noch einmal</Text>
    );
  }

  return (
    <form onSubmit={handlePurchaseOnAccountClick}>
      <Text fontSize="sm" pb="20px">
        Beim Kauf auf Rechnung muss der in Rechnung gestellte
        Betrag innerhalb von 14 Tagen auf das Konto des Rechnungsstellers
        überwiesen werden.
      </Text>

      <Text fontSize="md" pb="10px">
        <b>Rechnungsadresse</b>*
      </Text>

      <Text pb="25px">
        {user?.companyName && (
          <>
            {user?.companyName}
            <br />
          </>
        )}
        {user?.firstName} {user?.lastName}
        <br />
        {user?.street}
        <br />
        {user?.zip} {user?.city}
      </Text>

      <Text fontSize="xs" pb="20px">
        *Die Rechnungsadresse kann in den Kontoeinstellungen der App geändert
        werden.
      </Text>

      <Button
        width="full"
        type="submit"
        backgroundColor={primaryColor}
        color={secondaryColor}
        border={`${primaryColor} 1px solid`}
        _hover={{
          backgroundColor: "transparent",
          color: secondaryColor,
        }}
        disabled={loading}
        isLoading={loading}
      >
        Jetzt kaufen
      </Button>
    </form>
  );
};

export default PurchaseOnAccount;
