import React, { useState } from "react";
import { Box, Button, useToast } from "@chakra-ui/react";
import {
  useElements,
  useStripe,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { createSearchParams, useLocation } from "react-router-dom";
import { primaryColor, secondaryColor } from "../constants/colors";
import { PAYMENT_TYPES } from "../api/payment";

const StripePayment = ({ paymentIntentId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();
  const location = useLocation();

  const [processing, setProcessing] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/completed?${createSearchParams({
          token: location.state.token,
          payment_type: PAYMENT_TYPES.STRIPE,
          payment_intent_id: paymentIntentId ?? "null",
          platform: location.state.platform,
        }).toString()}`,
      },
    });

    if (error) {
      setProcessing(false);
      toast({
        status: "error",
        title: "Fehler",
        description: error.message,
      });
    }
  };

  return (
    <Box width="full">
      <form onSubmit={onSubmit}>
        <PaymentElement />
        <Box pt={4}>
          <Button
            width="full"
            type="submit"
            backgroundColor={primaryColor}
            color={secondaryColor}
            border={`${primaryColor} 1px solid`}
            _hover={{
              backgroundColor: "transparent",
              color: secondaryColor,
            }}
            disabled={processing}
            isLoading={processing}
          >
            Bezahlen
          </Button>
        </Box>
      </form>
    </Box>
  );
};
export default StripePayment;
