import { Box } from '@chakra-ui/react';
import React from 'react';
import Header from './Header';

const Layout = (props) => {
  const { children } = props;
  return (
    <Box height="full">
      <Header />
      {children}
    </Box>
  );
};
export default Layout;
