import { Flex, Image } from '@chakra-ui/react';
import React from 'react';

const Header = () => {
  return (
    <Flex as='header' py={4} justify='center' width='full'>
      <Image
        height='100%'
        display='block'
        src={"/assets/images/logo.png"}
        alt='logo'
        width={[32, 32, 48]}
      />
    </Flex>
  );
};
export default Header;
