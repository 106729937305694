import { backendUrl } from "./config";

export const PAYMENT_TYPES = {
  STRIPE: "stripe",
  PURCHASE_ON_ACCOUNT: "purchase_on_account",
};

export async function createStripeOrder(token) {
  return fetch(`${backendUrl}/cart/stripe/createpaymentintent`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .catch((err) => console.error(err));
}

export async function captureOrder(token, { type, id }) {
  let body = {
    type,
  };

  if (type === PAYMENT_TYPES.STRIPE) {
    body.stripePaymentIntentId = id;
  }

  return fetch(`${backendUrl}/cart/captureorder`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.error(err);
      return null;
    });
}
