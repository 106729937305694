import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import { createStripeOrder } from "../api/payment";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "../lib/stripe";
import StripePayment from "../components/stripePayment";
import PurchaseOnAccount from "../components/purchaseOnAccount";

const LOADING_STATES = {
  INITIAL_LOADING: "INITIAL_LOADING",
  PROCESSING_PAYMENT: "PROCESSING_PAYMENT",
  IDLE: "IDLE",
};

const PaymentPage = () => {
  const [loadingState, setLoadingState] = useState(
    LOADING_STATES.INITIAL_LOADING
  );
  const [stripeOrder, setStripeOrder] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { token, platform, cartItems, finalPrice } = location.state;

  useEffect(() => {
    setLoadingState(LOADING_STATES.INITIAL_LOADING);
    if (!cartItems) {
      navigate({
        pathname: "/checkout",
        search: createSearchParams({ token, platform }).toString(),
      });
    }
    createStripeOrder(token).then((order) => {
      setStripeOrder({
        clientSecret: order.clientSecret,
        paymentIntentId: order.paymentIntentId,
      });
      setLoadingState(LOADING_STATES.IDLE);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems, setStripeOrder, setLoadingState]);

  return loadingState !== LOADING_STATES.IDLE ? (
    <Flex px={12} py={12} flexDirection="column" alignItems="center">
      <Spinner />
      <Text pt={6}>
        {loadingState === LOADING_STATES.INITIAL_LOADING
          ? "Deine Zahlung wird vorbereitet. Einen Moment Geduld..."
          : "Deine Zahlung wird verarbeitet. Du wirst in wenigen Augenblicken weitergeleitet..."}
      </Text>
    </Flex>
  ) : (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: stripeOrder?.clientSecret,
      }}
    >
      <Flex
        px={[4, 4, 12]}
        flexDirection="column"
        justifyContent="space-between"
        height="calc(100% - 130px)"
      >
        <Box>
          <Flex pt={4} pb={6} justifyContent="center">
            <Heading as="h1" fontSize="2xl">
              Zahlung
            </Heading>
          </Flex>
          <Flex minWidth="full" justifyContent="center">
            <Text fontSize="lg" fontWeight="bold" pt={2} pb={7}>
              Gesamtbetrag: {finalPrice} €
            </Text>
          </Flex>
          <Flex width="full" justifyContent="center">
            <Tabs width={["full", "full", 1 / 3]} isFitted>
              <TabList>
                <Tab color="black">
                  Online bezahlen
                </Tab>
                <Tab color="black">Kauf auf Rechnung</Tab>
              </TabList>

              <TabPanels width={["full", "full", 1 / 3]}>
                <TabPanel>
                  <Flex minWidth="full" justifyContent="center">
                    <StripePayment
                      paymentIntentId={stripeOrder.paymentIntentId}
                    />
                  </Flex>
                </TabPanel>
                <TabPanel>
                  <PurchaseOnAccount />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Box>
        <Flex pt={6} pb={4} minWidth="full" justifyContent="space-between">
          <Button
            onClick={() => {
              navigate(-1);
            }}
          >
            Zurück
          </Button>
        </Flex>
      </Flex>
    </Elements>
  );
};

export default PaymentPage;
