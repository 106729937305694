import { backendUrl } from "./config";

export async function fetchCart(token) {
  return fetch(`${backendUrl}/cart/getcart`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .catch((err) => {
      console.error("Error", err);
      throw err;
    });
}

export async function fetchUser(token) {
  return fetch(`${backendUrl}/user/profile`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .catch((err) => {
      console.error("Error", err);
      throw err;
    });
}

export async function validateCart(token) {
  return fetch(`${backendUrl}/cart/validate`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.ok)
    .catch((err) => {
      console.error("Error: Some booking is not available anymore", err);
      throw err;
    });
}
